import { Box, Flex, Heading,Text, Link, Spacer,Button,useDisclosure,
  DrawerBody,DrawerHeader,DrawerOverlay,DrawerContent,Drawer,List,ListItem
  } from '@chakra-ui/react'
  
  import { Link as GatsbyLink,navigate } from 'gatsby'
  import PropTypes from 'prop-types'
  import React from 'react'
  import ThemeToggle from './theme-toggle'
  import MyContext from '../context/MyContext'
  import { motion } from 'framer-motion'
  import {FaAlignJustify} from 'react-icons/fa'
  
  
  
  
  function PlacementExample() {
    const {engl} = React.useContext(MyContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [placement] = React.useState('right')
  
    return (
      <>
      <Box >
        <Button colorScheme='gray' onClick={onOpen}>
         <FaAlignJustify fontSize="20px" />
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>Menu</DrawerHeader>
            <DrawerBody>
            <motion.div
             initial={{opacity:0,height:0}}
             animate={{opacity:1,height:900}}
             transition={{duration:1,ease:'easeInOut'}}
            >
              <List cursor="pointer" onClick={onClose}  fontSize="2xl" >
  
         
                  <ListItem onClick={()=>navigate("/")} pb="1">
                      <Link to="/"> <Text letterSpacing=".09rem" fontSize="xl" fontWeight="400">{!engl?"Leistungen":"Services"}</Text></Link>
                  </ListItem>
                  <ListItem onClick={()=>navigate("/me")} pb="1">
                      <Text letterSpacing=".09rem" fontSize="xl" fontWeight="400">{!engl?"Biografie":"Biography"}</Text>
                  </ListItem>
                  <ListItem onClick={()=>navigate("/projekte")} py="1">
                      <Text letterSpacing=".09rem" fontSize="xl" fontWeight="400" >{engl?"Projects":"Projekte"}</Text>
                  </ListItem>
                  <ListItem onClick={()=>navigate("/unterricht")} py="1">
                      <Text letterSpacing=".09rem" fontSize="xl" fontWeight="400">{engl?"Lessons":"Unterricht"}</Text>
                  </ListItem>
                  <ListItem onClick={()=>navigate("/termine")} py="1">
                      <Text letterSpacing=".09rem" fontSize="xl" fontWeight="400">{!engl?"Termine":"Events"}</Text>
                  </ListItem>
                  {/* <ListItem onClick={()=>navigate("/galerie")} py="1">
                      <Text letterSpacing=".09rem" fontSize="xl"fontWeight="400">{!engl?"Galerie":"Gallery"}</Text>
                  </ListItem> */}
                  <ListItem onClick={()=>navigate("/videos")} py="1">
                     <Text letterSpacing=".09rem" fontSize="xl"fontWeight="400">{!engl?"Media":"Medien"}</Text>
                  </ListItem>
                 
              </List>
              </motion.div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        </Box>
      </>
    )
  }
  
  const Header = ({ siteTitle }) => {
    
    const {engl,setEng} =React.useContext(MyContext)
    return(
      
   
          <Box m="3">
       
          
            
            
            <Spacer />
            <Flex my="3"  maxWidth={1100} mr="10"  flexDirection={["row","row"]} alignItems="flex-end" justifyContent="flex-end">
            <Box mb="2" pr="2" >
              <ThemeToggle />
            </Box>
            <Box px="4" mb="2">
              <Button onClick={()=>setEng(!engl)}>{engl?'De':'En'}</Button>
            </Box>
            <Box position="relative"  mb="4" pr="2" mt="9"   style={{cursor:'pointer'}} ><Box position="absolute" zIndex="4" top="-8" ><PlacementExample/></Box></Box>
            
            </Flex>
            <Box>
           {
             typeof window !="undefined" && window.location.pathname!=="/"?
             <Link
               activeStyle={{outlineColor:'white'}}
                as={GatsbyLink}
                to="/"
                
                _hover={{ textDecor: 'none',outlineColor:'white' }}
              >
                 <Heading  letterSpacing='0.19rem' fontSize={["3xl","4xl"]} as="h1">
                 {siteTitle}
                 </Heading>
                
                 
              </Link>
           : 
           <Heading mt="4" style={{cursor:'pointer'}}  letterSpacing='0.19rem' fontSize={["3xl","4xl"]} as="h1">
           {siteTitle}
           </Heading>}
            </Box>
            <Box pl="0">
            {
                   engl?<Text px="0" fontSize="sm" >Piano&nbsp;&nbsp;Guitar&nbsp;&nbsp; Songwriting & Arrangement &nbsp;&nbsp; Classical / Rock / Pop</Text>:
                   <Text px="0" fontSize="sm" >Klavier&nbsp;&nbsp;Gitarre&nbsp;&nbsp; Songwriting & Arrangement &nbsp;&nbsp; Klassik / Rock / Pop</Text>
  
                 }
            </Box>   
          </Box>
      
      
  
  
    )
  }
  
  Header.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Header.defaultProps = {
    siteTitle: ``,
  }
  
  export default Header