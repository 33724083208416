import { ChakraProvider,extendTheme} from '@chakra-ui/react'
import React from 'react'
import Layout from './components/layout'
import {GlobalStyle } from '@chakra-ui/react'
import HG from './images/benjamin/1.jpeg'
import { mode } from '@chakra-ui/theme-tools';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: 'true',
}
const styles = {
  global: props => ({
    body: {
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('gray.100', '#141214')(props),
    },
  }),
};

const Heading = {

  baseStyle:{
    fontWeight: 300,
    py:0,
     },
  sizes: {
    sm: {
      fontSize: 'sm',
      py:"2",
      
    },
    md: {
      fontSize: 'md',
      py:"3",
     
    },
    xl:{
      fontSize:'xl',
      my:0,
      py:0
    },

    '2xl':{
      fontSize:'2xl',
      my:0,
      py:0
      
    }
  },
 variants:{ 
  MeH: {
    lineHeights:1.3,
    fontWeight:400,
    fontSize:['xl','2xl'],
    px:0,
    py:4
   
    },
  ieH: {
      lineHeights:1.0,
      fontWeight:350,
      fontSize:['xl','2xl'],
      px:0,
      py:4
     
      }
   },
   defaultProps: {
     size:'2xl',
   }
  }

const Text = {

  baseStyle:{
    fontWeight: 300,
     },
  sizes: {
    sm: {
      fontSize: 'sm',
      py:"2",
    },
    md: {
      fontSize: 'md',
      py:"2",
    },
    xl:{
      fontSize:'2xl',
      py:3,
    }
  },
 variants:{
  FotoTitel: {
    fontSize:['sm', 'md'],
    fontWeight:"300",
    pt:"-1"
  },
  MeA: {
    lineHeights:1.3,
   
    fontSize:['lg','xl'],
    px:0,
    mb:0,
   },
   VeA: {
    lineHeights:1.1,
    fontSize:['md','lg','xl'],
    px:0,
    py:0,
    mb:0,
    fontWeight: 300,
    }
   },
   defaultProps: {
     size:'xl',
   }
  }

export const theme = extendTheme({

components: {
 Text,
 Heading,
 styles
 },
 
//  styles: {
//   global: {
//     "html, body": {
//       height: "100%",
//       backgroundImage:`url(${HG})`,  
//     },
//   },
// },

colors: {
  brand: {
    100: '#E0AAFF',
    200: '#C77DFF',
    300: '#9D4EDD',
    400: '#7B2CBF',
    500: '#5A189A',
    600: '#3C096C',
    700: '#240046',
    800: '#10002B',
    900: '#070014',
  },
},
fontSizes: {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '20px',
  '2xl': '24px',
  '3xl': '28px',
  '4xl': '36px',
  '5xl': '48px',
  '6xl': '64px',
},
fontWeights: {
  normal: 400,
  medium: 500,
  bold: 700,
},
lineHeights: {
  normal: 'normal',
  none: '1',
  shorter: '1.25',
  short: '1.375',
  base: '1.5',
  tall: '1.625',
  taller: '2',
},
letterSpacings: {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
},
breakpoints: ['30em', '48em', '62em', '80em'],

})


export const wrapPageElement = ({ element }) => {
   
  return (

    <ChakraProvider theme={theme} resetCSS>
      <GlobalStyle />
      <Layout>{element}</Layout>  
    </ChakraProvider>
  )
}