/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import { Box,Text,useColorMode,Button,List,ListItem } from '@chakra-ui/react'
 import PropTypes from 'prop-types'
 import React, {useEffect, useState } from 'react'
 import Header from './header'
 import MyContext from '../context/MyContext'
 import { useMetaData } from '../hooks/use-meta-data'
 import { useLocalStorage } from '../hooks/use-localstorage'
 import {Link} from 'gatsby'
 import HG from '../images/benjamin/1.jpeg'
 
 const Hintergrundbild = {
backgroundImage:`url(${HG})`,
backgroundRepeat: 'no-repeat',
backgroundSize: 'cover'

 }
 const Layout = ({ children }) => {
 const [engl,setEng] = useState(false)
 
 const data = useMetaData()
 const colorMode=useColorMode()
 const [name,setName] = useLocalStorage('name',"")
 
 const Meldung=()=>{
  
  return(
    <Box maxWidth="400" p="3" color="white" backgroundColor={'gray.500'} my="6">
      
      <Text p="0" fontSize="md">
        Beachten Sie unsere Datenschutzbestimmungen<br/>
        Sind Sie mit unserer Datenverwaltung einverstanden?
        
      </Text>
       
      <Box> 
            <Button my="2" onClick={()=>{
                setName("yes-einverstanden")
              }}>Ja
            </Button>{" "}
            <Button my="2" onClick={()=>{
                setName("nein-einverstanden")
              }}>Nein 
            </Button>
            </Box>
      <Link to="/datenschutz"> &rarr; Unsere Datenschutzbestimmungen lesen</Link>
    </Box>
  )
}
// useEffect(()=>{ 
// return colorMode.toggleColorMode ()
// },[]
// )
   return (
    <Box> 
     {/* <Box style={Hintergrundbild}> */}
    <MyContext.Provider value={{engl,setEng,name,setName}}>
    
      {
      name===""  && 
      <Box bg="grey.700" p="10" position='absolute' zIndex="700" width="100vw" maxWidth="1300" height="300" top="0" left="0" right="0 p=4">
        
      <Meldung />
     
        
       <Box>     
      </Box>
           
      
    </Box>
   
   }
   
     <Box
         as="div"
         margin="0 auto"
         maxWidth="1200px"
         mx={[7,10,14]}
         height="100%"
        
       >
          <Header siteTitle={data.site.siteMetadata.title || `Benjamin`} />  
         <Box shadow="gray blue"  as="main">{children}</Box>
         <Box mt="20">
       <List p="0" display="flex" flexDirection={['column','row']} fontSize="md" mt="-4" >
                <ListItem p="1">
                    <Link to="/kontakt"> <Text fontSize="lg" fontWeight="200">{!engl?"Kontakt":"Contact"}</Text></Link>
                </ListItem>
                <ListItem p="1">
                    <Link to="/impressum"> <Text fontSize="lg" fontWeight="200" >{engl?"Impressum":"Impressum"}</Text></Link>
                </ListItem>
                <ListItem p="1">
                    <Link to="/datenschutz"> <Text fontSize="lg" fontWeight="200">{!engl?"Datenschutz":"Protect"}</Text></Link>
                </ListItem>
               
               
            </List>
       </Box>
        <Box bg={colorMode.colorMode==="dark"?"gray.800":"whiteAlpha.600"} rounded="2" position="relative" zIndex="-3" top="99%" bottom="0px" as="footer" marginTop="2rem" fontSize="md">
        <Text color={colorMode.colorMode==="light"?"gray.800":'white'}  fontSize="md">
           © {new Date().getFullYear()}, Benjamin Reiter
           {` `}
        benjamin.s.w.reiter@gmail.com</Text>
         </Box>
       </Box>
      
       </MyContext.Provider>
       </Box>

   )}
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout